(function () {
  'use strict';

  angular
    .module('wbNavigation')
    .config(config);

  function config($translateProvider) {
    $translateProvider.translations('hu', {
      'navigation.learnMoreButton': 'Tudjon meg többet kártyáinkról',
      'navigation.learnMoreButton.href': 'http://edenredkartya.hu/',
      'navigation.onlineCardManagement': 'Online Ügyintézés',
      'navigation.newCardOrder': 'Új kártya rendelés',
      'navigation.topUp': 'Kártyafeltöltés',
      'navigation.vip': 'Biztonságos fájlküldés',
      'navigation.previousOrders': 'Korábbi rendelések',
      'navigation.faq': 'GYIK',
      'navigation.toggle': 'Navigáció',
      'navigation.languageSwitcher': 'Nyelvválasztó',
      'navigation.csnFileDownload': 'Kártyaazonsítók megrendelésenként',
      'navigation.completeCsnLists': 'Kártyaazonosítók teljes listái',
      'navigation.orderReplacementCard': 'Cserekártya rendelés',
      'navigation.multiWalletDataReconciliationList': 'Korábbi Ticket Restaurant SZÉP Kártya adategyeztetések'
    });
  }
}());
